const state = {
    jobExamples: [
        {
            index: 2,
            id: 1,
            jobType: 'Foto',
            databaseName: 'photo',
            title: 'Fotos Mídias Sociais',
            description: 'Fotos para publicação em mídias sociais: Facebook e Instagram',
            remuneration: 500,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: 3,
            placements: ['social_networks_publication']
        },
        {
            index: 2,
            id: 2,
            jobType: 'Foto',
            databaseName: 'photo',
            title: 'Fotos Mídias Sociais',
            description: 'Fotos para publicação em mídias sociais: Facebook e Instagram',
            remuneration: 1000,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: 3,
            placements: ['social_networks_publication']
        },
        {
            index: 2,
            id: 3,
            jobType: 'Foto',
            databaseName: 'photo',
            title: 'Lookbook E-commerce',
            description: 'Fotos para catálogo impresso (Lookbook) e online (E-commerce)',
            remuneration: 800,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: 3,
            placements: ['e_commerce']
        },
        {
            index: 2,
            id: 4,
            jobType: 'Foto',
            databaseName: 'photo',
            title: 'Lookbook E-commerce',
            description: 'Fotos para catálogo impresso (Lookbook) e online (E-commerce)',
            remuneration: 1500,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: 3,
            placements: ['e_commerce']
        },
        {
            index: 2,
            id: 5,
            jobType: 'Foto',
            databaseName: 'photo',
            title: 'Campanha',
            description: 'Modelo para fotos de campanha publicitária',
            remuneration: 1000,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: 3,
            placements: ['advertising_form']
        },
        {
            index: 2,
            id: 6,
            jobType: 'Foto',
            databaseName: 'photo',
            title: 'Campanha',
            description: 'Modelo para fotos de campanha publicitária',
            remuneration: 2500,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: 3,
            placements: ['advertising_form']
        },
        {
            index: 0,
            id: 7,
            jobType: 'Evento',
            databaseName: 'event',
            title: 'Promotor para Feira',
            description: 'Promotor para participação em estande em feira de negócios',
            remuneration: 250,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: null
        },
        {
            index: 0,
            id: 8,
            jobType: 'Evento',
            databaseName: 'event',
            title: 'Promotor para Feira',
            description: 'Promotor para participação em estande em feira de negócios',
            remuneration: 400,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: null
        },
        {
            index: 0,
            id: 9,
            jobType: 'Evento',
            databaseName: 'event',
            title: 'Recepção em Evento Corporativo',
            description: 'Promotor para recepção em evento corporativo',
            remuneration: 200,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: null
        },
        {
            index: 0,
            id: 10,
            jobType: 'Evento',
            databaseName: 'event',
            title: 'Recepção em Evento Corporativo',
            description: 'Promotor para recepção em evento corporativo',
            remuneration: 350,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: null
        },
        {
            index: 0,
            id: 11,
            jobType: 'Evento',
            databaseName: 'event',
            title: 'Distribuição de Panfletos',
            description: 'Promotor para ação e ativação. Distribuição de panfletos e ativação de clientes',
            remuneration: 150,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: null
        },
        {
            index: 0,
            id: 12,
            jobType: 'Evento',
            databaseName: 'event',
            title: 'Distribuição de Panfletos',
            description: 'Promotor experiente para ação e ativação. Distribuição de panfletos e ativação de clientes',
            remuneration: 250,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: null
        },
        {
            index: 3,
            id: 13,
            jobType: 'Vídeo',
            databaseName: 'video',
            title: 'Figurante',
            description: 'Figurante para filmagem. O figurante aparecerá apenas como parte do cenário, não será focado',
            remuneration: 150,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: 3,
            placements: ['outdoor']
        },
        {
            index: 3,
            id: 14,
            jobType: 'Vídeo',
            databaseName: 'video',
            title: 'Vídeo Corporativo',
            description: 'Vídeo institucional para veiculação interna de empresa. Sem fala.',
            remuneration: 1000,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: 3,
            placements: ['outdoor']
        },
        {
            index: 3,
            id: 15,
            jobType: 'Vídeo',
            databaseName: 'video',
            title: 'Vídeo Corporativo',
            description: 'Vídeo institucional para veiculação interna de empresa. Ator experiente e bem articulado.',
            remuneration: 3000,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: 3,
            placements: ['outdoor']
        },
        {
            index: 3,
            id: 16,
            jobType: 'Vídeo',
            databaseName: 'video',
            title: 'Vídeo - Veiculação em TV',
            description: 'Vídeo de propaganda. Veiculação em TV.',
            remuneration: 5000,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: 3,
            placements: ['outdoor']
        },
        {
            index: 4,
            id: 17,
            jobType: 'Modelo de Prova / Showroom',
            title: 'Showroom',
            databaseName: 'showroom',
            description: 'Profissional para realizar prova de roupa',
            remuneration: 300,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: null
        },
        {
            index: 4,
            id: 18,
            jobType: 'Modelo de Prova / Showroom',
            databaseName: 'showroom',
            title: 'Showroom',
            description: 'Modelo para realizar prova de roupa',
            remuneration: 500,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: null
        },
        {
            index: 1,
            id: 19,
            jobType: 'Modelo para Desfile',
            databaseName: 'fashion',
            title: 'Desfile',
            description: 'Modelo para realizar desfile de moda',
            remuneration: 600,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: null
        },
        {
            index: 1,
            id: 20,
            jobType: 'Modelo para Desfile',
            databaseName: 'fashion',
            title: 'Desfile',
            description: 'Modelo para realizar desfile de moda',
            remuneration: 1000,
            feeding: 'feeding_by_fashion_model',
            transportation: 'transportation_by_fashion_model',
            imageRights: null
        }
    ]
};
const getters = {
    getJobExampleById: (state) => id => {
        const job = state.jobExamples.find(jobExample => {
            return jobExample.id === id;
        })
        return job
    }
};

export default {
    namespaced: true,
    state,
    getters
};
